<template>
  <b-card>
    <b-overlay
      :show="!invoices"
      rounded="sm"
    >
      <div class="invoice-table">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="invoices"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :sort-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'invoiceNo'">
              <router-link
                v-if="props.row.is_credit_note"
                :to="`/invoices/${props.row.id}`"
              >
                {{ props.row.credit_note }}
              </router-link>
              <router-link
                v-else
                :to="`/invoices/${props.row.id}`"
              >
                {{ props.row.version === 1 ? props.row.invoice : `TMC${String(props.row.invoice_number_v2).padStart(4, '0')}` }}
              </router-link>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: '#',
          field: 'invoiceNo',
          sortable: true,
          sortFn: this.sortFnID,
        },
        {
          label: 'id',
          field: 'customId',
          hidden: true,
        },
        {
          label: 'Client',
          field: 'customClient',
          width: '250px',
        },
        {
          label: 'Package',
          field: 'customPackage',
          sortable: true,
          sortFn: this.sortPackage,
        },
        {
          label: 'Issued On',
          field: 'customIssuedOn',
          sortable: true,
          sortFn: this.sortFnIssuedOn,
        },
        {
          label: 'Invoice Value',
          field: 'customInvoiceValue',
          sortable: true,
          sortFn: this.sortFnInvoiceValue,
        },
        {
          label: 'Period',
          field: 'customPeriod',
          sortable: true,
          sortFn: this.sortFnPeriod,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    invoices() {
      return this.$store.state.invoice.overdueCreditNotes
    },
  },
  created() {
    this.fetchOverdueCreditNotes()
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.invoices) {
        clearInterval(refreshId)
        this.getTableRows(this.invoices)
      }
    }, 100)
  },
  methods: {
    getTableRows(invoices) {
      invoices.forEach(invoice => {
        const item = {
          id: invoice.id,
          is_credit_note: invoice.is_credit_note,
          credit_note: invoice.credit_note,
          version: invoice.invoice,
          invoice_number_v2: invoice.invoice_number_v2,
          currency: invoice.currency,
          invoice_value: invoice.invoice_value,
          vat: invoice.vat,
          start_date: invoice.start_date,
          end_date: invoice.end_date,
          invoice_rows: invoice.invoice_rows,
          customId: this.getId(invoice),
          customPackage: this.getPackage(invoice),
          customIssuedOn: this.formatDate(invoice.issue_date),
          customInvoiceValue: `${invoice.currency.toUpperCase()} ${parseFloat(invoice.invoice_value).toFixed(2)} (inc.VAT ${invoice.vat}%)`,
          customPeriod: `${this.formatDate(invoice.start_date)} - ${this.formatDate(invoice.end_date)}`,
          customClient: invoice.customClient,
        }
        this.rows.push(item)
      })
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchOverdueCreditNotes() {
      const { last30days } = this.$route.query
      let getLast30days = 0
      if (typeof last30days !== 'undefined') getLast30days = last30days

      const payload = {
        last30days: getLast30days,
      }

      this.$store.dispatch('invoice/fetchOverdueCreditNotes', payload)
        .catch(error => { console.log(error) })
    },
    getPackage(inv) {
      let str = ''

      if (inv.type !== 4 && inv.type !== 3) str = inv.invoice_rows[0].item ?? 'Custom'
      else if (inv.type === 4) str = 'Optional feature'
      else if (inv.overage === 'conversions') str = 'Overage Conversions'
      else str = 'Overage Clicks'

      return str
    },
    getId(item) {
      if (item.is_credit_note) return Number(item.credit_note)
      if (item.version === 1) return Number(item.invoice)
      return Number(item.invoice_number_v2)
    },

    getInvoiceValue(item) {
      const exchange_rate = 4.9264
      const value = parseFloat(item.invoice_value)
      const cur = item.currency.toUpperCase()
      if (cur === 'RON') return value / exchange_rate
      return value
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    sortPackage(x, y, col, rowX, rowY) { // eslint-disable-line
      let value1 = this.getPackage(rowX)
      let value2 = this.getPackage(rowY)
      // convert to string and lowercase
      value1 = value1.toString().toLowerCase()
      value2 = value2.toString().toLowerCase()
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnIssuedOn(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.start_date))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.start_date))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnPeriod(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.start_date))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.start_date))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnID(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = this.getId(rowX)
      const value2 = this.getId(rowY)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnInvoiceValue(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = this.getInvoiceValue(rowX)
      const value2 = this.getInvoiceValue(rowY)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.invoice-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
