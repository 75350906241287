var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":!_vm.invoices,"rounded":"sm"}},[_c('div',{staticClass:"invoice-table"},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),(_vm.invoices)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"sort-options":{
          enabled: true,
        },"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm },"pagination-options":{
          enabled: true,
          perPage:_vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'invoiceNo')?_c('span',[(props.row.is_credit_note)?_c('router-link',{attrs:{"to":("/invoices/" + (props.row.id))}},[_vm._v(" "+_vm._s(props.row.credit_note)+" ")]):_c('router-link',{attrs:{"to":("/invoices/" + (props.row.id))}},[_vm._v(" "+_vm._s(props.row.version === 1 ? props.row.invoice : ("TMC" + (String(props.row.invoice_number_v2).padStart(4, '0'))))+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap mt-1"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['50','100','150']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,3678580223)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }